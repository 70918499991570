import { callDelete, callGet, callPatch } from "api/integration/call";
import { REQ } from "constants/index";

export const getAllUsersEndPoint = async (token) => {
  return await callGet(REQ.USER.GET_ALL, token);
};

export const updateUsersByIdEndPoint = async (token, data, id) => {
  return await callPatch(
    REQ.USER.UPDATE_BY_ID.replace(":id", id),
    data,
    token
  );
};

export const deleteUserEndPoint = async (token, id) => {
  return await callDelete(REQ.USER.DELETE_BY_ID.replace(":id", id), {}, token);
};