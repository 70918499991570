import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddBrandModal from "../AddBrandModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import IconButton from "components/IconButton";
import { useUserContext } from "context";
import { HTTP_STATUS_CODES } from "constants";
import { updateBrandByIdEndPoint } from "api/endpoints/brand";
import { toastSuccess } from "api/integration/toast";

const ActionDropdown = ({
  openModal,
  setOpenModal,
  // onUpdateBrand,
  onDeleteBrand,
  isEditing,
  setIsEditing,
  row,
}) => {
  const { token, onGetAllBrand } = useUserContext();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  const onMenuItemClick = () => {};

  const onEditRow = () => {
    setOpenModal(true);
    setIsEditing(true);
  };

  const onUpdateStatus = async (status) => {
    const payload = {
      status,
    };

    const response = await updateBrandByIdEndPoint(token, payload, row.id);
    if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
      toastSuccess("Status Changed Successfully");
      onGetAllBrand();
    }
  };

  return (
    <div className="flex ">
      <div
        className="relative cursor-pointer p-1 font-medium border py-1 px-2 rounded-md "
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MoreHorizIcon />
        {
          <div
            className={`absolute top-3 left-0  transition-all transition-duration:500ms bg-white rounded-md shadow-lg mt-3  w-[130px] z-10 overflow-hidden  `}
            style={{ maxHeight: isDropdownOpen ? "250px" : "0px" }}
          >
            <div className="text-left pl-4 py-3 text-[#00000073]">
              Update Status
            </div>
            <div className="text-black px-5 text-[14px] pb-2 font-normal cursor-pointer text-left">
              {row && row.status.toLowerCase() === "active" ? (
                <IconButton
                  text="block"
                  icon={<BlockIcon sx={{ fontSize: "16px" }} />}
                  className={"hover:text-[#ff1d52]"}
                  onClick={() => onUpdateStatus("block")}
                />
              ) : (
                <IconButton
                  text="active"
                  icon={<WbSunnyIcon sx={{ fontSize: "16px" }} />}
                  className={"hover:text-[#ff1d52]"}
                  onClick={() => onUpdateStatus("active")}
                />
              )}
            </div>
            <div className="text-left pl-4 py-3 text-[#00000073]">
              Update Brand
            </div>
            <div
              className="text-black px-5 pb-2 text-[14px] font-normal cursor-pointer text-left"
              onClick={onEditRow}
            >
              <IconButton
                text="edit"
                icon={<EditIcon sx={{ fontSize: "16px" }} />}
                className={"hover:text-[#ff1d52]"}
              />
            </div>
            <div
              className="text-black px-5 pb-3 text-[14px] font-normal cursor-pointer text-left"
              onClick={onMenuItemClick}
            >
              <IconButton
                text="delete"
                icon={<DeleteIcon sx={{ fontSize: "16px" }} />}
                className={"text-red-600"}
                onClick={onDeleteBrand}
              />
            </div>

            <AddBrandModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              // onUpdateBrand={onUpdateBrand}
              isEditing={isEditing}
              row={row}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default ActionDropdown;
