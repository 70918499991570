import { callGet, callPost } from "api/integration/call";
import { REQ } from "constants/index";

export const authSigninEndpoint = async (token, data) => {
  return await callPost(REQ.USER.SIGN_IN, data, token);
};

export const authVerifyEndpoint = async (token) => {
  return await callGet(REQ.USER.VERIFY, token);
};
