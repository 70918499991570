import AWS from 'aws-sdk';  // Import your AWS SDK configuration 

const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION, // e.g., 'us-east-1'
});


export default s3;
