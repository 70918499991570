import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useUserContext } from "context";
import { HTTP_STATUS_CODES } from "constants/index.js";
import ImageUploader from "utils/ImageUploader";
import { FormControl, TextField } from "@mui/material";
import {
  postBrandsEndPoint,
  updateBrandByIdEndPoint,
} from "api/endpoints/brand";
import { toastSuccess } from "api/integration/toast";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const AddBrandModal = ({ onClose, open, isEditing, row }) => {
  const { token, onGetAllBrand } = useUserContext();

  const [logoUrl, setLogoUrl] = useState(row?.logo || "");
  const [thumbnailUrl, setThumbnailUrl] = useState(row?.thumbnail || "");
  const [bannerUrl, setBannerUrl] = useState(row?.banner_desktop || "");
  const [mobileBannerUrl, setMobileBannerUrl] = useState(
    row?.banner_mobile || ""
  );
  const [brandName, setBrandName] = useState(row?.name || "");
  const [deliveryCharges, setDeliveryCharges] = useState(
    row?.delivery_charges || ""
  );
  const [deliveryOffer, setDeliveryOffer] = useState(row?.delivery_offer || "");

  const onAddData = async () => {
    const response = await postBrandsEndPoint(token, {
      name: brandName,
      delivery_charges: deliveryCharges,
      delivery_offer: deliveryOffer,
      logo: logoUrl,
      thumbnail: thumbnailUrl,
      banner_desktop: bannerUrl,
      banner_mobile: mobileBannerUrl,
    });
    if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
      onClose();
      onGetAllBrand();
      toastSuccess("Brand added Successfully");
    }
  };
  const onUpdateData = async () => {
    const brand = {
      name: brandName,
      delivery_charges: deliveryCharges,
      delivery_offer: deliveryOffer,
      logo: logoUrl,
      thumbnail: thumbnailUrl,
      banner_desktop: bannerUrl,
      banner_mobile: mobileBannerUrl,
    };
    const response = await updateBrandByIdEndPoint(token, brand, row.id);
    if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
      onClose();
      onGetAllBrand();
      toastSuccess("action performed Successfully");
    }
  };

  const [showImage, setShowImage] = useState(true);

  const handleImageUpload = (imageURL) => {
    setLogoUrl(imageURL);
    setShowImage(false);
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "520px",
            borderRadius: "15px",
          },
          "&::-webkit-scrollbar": {
            width: 4,
            height: 4,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "white",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#FF1D52",
            borderRadius: 2,
          },
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          background: "#ff1d52",
          color: "white",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {isEditing ? "Edit Brand" : "Create New Brand"}
        <DialogActions>
          <CloseIcon onClick={onClose} sx={{ fontSize: "18px" }} />
        </DialogActions>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <FormControl
            sx={{
              width: "100%",
              marginTop: 4,
            }}
            size="small"
          >
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              size="small"
              margin="normal"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Delivery Charges"
              variant="outlined"
              size="small"
              margin="normal"
              value={deliveryCharges}
              onChange={(e) => setDeliveryCharges(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Delivery Offer"
              variant="outlined"
              size="small"
              margin="normal"
              value={deliveryOffer}
              onChange={(e) => setDeliveryOffer(e.target.value)}
            />
            <Button
              variant="contained"
              sx={{
                marginTop: "20px",
                background: "transparent",
                color: "gray",
                boxShadow: "none",
                border: "1px solid #e5e7eb",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  background: "none",
                },
              }}
              component="label"
            >
              Upload Logo
              <ImageUploader
                setImageURL={handleImageUpload}
              />
            </Button>
            {logoUrl && (
              <img src={logoUrl} alt="Logo" className="w-60 mx-auto my-3 " />
            )}
            <Button
              variant="contained"
              sx={{
                marginTop: "20px",
                background: "transparent",
                color: "gray",
                boxShadow: "none",
                border: "1px solid #e5e7eb",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  background: "none",
                },
              }}
              component="label"
            >
              Upload Thumbnail
              <ImageUploader
                setImageURL={setThumbnailUrl}
                imageURL={thumbnailUrl}
                showImage={showImage}
              />
            </Button>
            {thumbnailUrl && (
              <img
                src={thumbnailUrl}
                alt="thumbnail"
                className="w-60 mx-auto my-3 "
              />
            )}

            <Button
              variant="contained"
              sx={{
                marginTop: "20px",
                background: "transparent",
                color: "gray",
                boxShadow: "none",
                border: "1px solid #e5e7eb",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  background: "none",
                },
              }}
              component="label"
            >
              Upload Desktop Banner
              <ImageUploader
                setImageURL={setBannerUrl}
                imageURL={bannerUrl}
                showImage={showImage}
              />
            </Button>
            {bannerUrl && (
              <img
                src={bannerUrl}
                alt="thumbnail"
                className="w-60 mx-auto my-3 "
              />
            )}

            <Button
              variant="contained"
              sx={{
                marginTop: "20px",
                background: "transparent",
                color: "gray",
                boxShadow: "none",
                border: "1px solid #e5e7eb",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  background: "none",
                },
              }}
              component="label"
            >
              Upload Mobile Banner
              <ImageUploader
                setImageURL={setMobileBannerUrl}
                imageURL={mobileBannerUrl}
                showImage={showImage}
              />
            </Button>
            {mobileBannerUrl && (
              <img
                src={mobileBannerUrl}
                alt="thumbnail"
                className="w-60 mx-auto my-3 "
              />
            )}

            {isEditing ? (
              <Button
                sx={{
                  background: "#FF1D52",
                  color: "white",
                  marginTop: "20px",
                  "&:hover": {
                    background: "#FF005C",
                  },
                }}
                onClick={onUpdateData}
              >
                Update
              </Button>
            ) : (
              <Button
                sx={{
                  background: "#FF1D52",
                  color: "white",
                  marginTop: "20px",
                  "&:hover": {
                    background: "#FF005C",
                  },
                }}
                onClick={onAddData}
              >
                Create
              </Button>
            )}
          </FormControl>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AddBrandModal;
