import React from "react";

const IconButton = ({ text, icon, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`flex gap-2 items-center justify-center capitalize cursor-pointer text-md ${className}`}
    >
      {icon}
      {text}
    </button>
  );
};

export default IconButton;
