import React, { useState } from "react";
import Label from "../Label";
import Input from "../TextField";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import { toastError, toastSuccess } from "api/integration/toast";
import { authSigninEndpoint } from "api/endpoints/auth";
import { useUserContext } from "context";
import { HTTP_STATUS_CODES } from "constants/index";
import Loader from "components/Loader";

const SignInForm = () => {
  const { token, setToken, setLoading, setUser, setAuthenticated } = useUserContext();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  const [formData, setFormData] = useState({
    phone_number: "",
    password: "",
  });
  const [isShow, setIsShow] = useState(false);

  const setPassword = () => {
    setIsShow(!isShow);
  };

  const validateField = (string, length) => {
    return string.length >= length ? true : false;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validateField(formData.phone_number, 13)) {
      return toastError("Phone number must be minimum 13 characters.");
    }

    if (!validateField(formData.password, 8)) {
      return toastError("Password must be minimum 8 characters.");
    }

    setIsButtonDisabled(true);
    setIsLoading(true);

    const response = await authSigninEndpoint(token, formData);

    if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
      toastSuccess(response.message);
      setToken(response.data.accessToken);
      setAuthenticated(true);
      setUser(response.data.user);
      setLoading(true);

      setFormData({
        phone_number: "",
        password: "",
      });
    } else {
      toastError(response.message);
    }

    setTimeout(() => {
      setIsButtonDisabled(false);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <form onSubmit={onSubmit} className="w-full">
      <Label star={"*"}>Phone Number</Label>
      <Input
        placeholder={"Min. 13 characters"}
        name="phone_number"
        value={formData.phone_number}
        changeColor={(e) => {
          setFormData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
          });
        }}
      />
      <div className="pt-6 mb-[24px] relative">
        <Label star={"*"}>Password</Label>
        <Input
          placeholder={"Min. 8 characters"}
          type={!isShow ? "password" : "text"}
          name="password"
          value={formData.password}
          changeColor={(e) => {
            setFormData((prev) => {
              return { ...prev, [e.target.name]: e.target.value };
            });
          }}
        />
        {isShow ? (
          <VisibilityOffSharpIcon
            onClick={setPassword}
            className="absolute text-[1rem] right-[4%] cursor-pointer mt-4 text-[#a0aec0]"
          />
        ) : (
          <VisibilityOutlinedIcon
            onClick={setPassword}
            className="absolute text-[1rem] right-[4%] cursor-pointer mt-4 text-[#a0aec0]"
          />
        )}
      </div>
      <div className="flex mb-[24px]">
        <input
          className="inpStyle accent-[#ff2f60] h-[15px] w-[15px] cursor-pointer text-[#2d3748] mt-1"
          type="checkbox"
          id="logged-in-checkbox"
        />
        <label htmlFor="logged-in-checkbox">
          <Label
            className={"font-normal text-[0.775rem] pl-2 pt-[1px] cursor-pointer"}
          >
            Keep me logged in
          </Label>
        </label>
      </div>
      <button
        disabled={isButtonDisabled}
        className={`setButton w-full h-[50px] font-medium text-[0.875rem] text-[#ffffff]
        ${formData.password || formData.phone_number
            ? "bg-[#ff2f60] hovButton"
            : "bg-[#f9a7bf] cursor-not-allowed"
          }
        `}
      >
        Sign In
      </button>
      {isLoading && <Loader />}
    </form>
  );
};

export default SignInForm;
