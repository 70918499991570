import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { dateFormatter } from "utils/numberFormatter/date";

const BrandModal = ({ rowData, onClose, open }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "450px",
            borderRadius: "15px",
          },
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          background: "#ff1d52",
          color: "white",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor:"pointer"
        }}
      >
        {"Brand Detail"}
        <DialogActions>
          <CloseIcon onClick={onClose} sx={{ fontSize: "18px" }} />
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <img
            src={rowData.logo}
            alt=""
            className="my-4 mx-auto w-[30%] block"
          />
          <div className="flex flex-col items-center justify-center mb-4 ">
            <span className="text-[#ff1d52] border-b">{rowData.name}</span>
          </div>
          <div className="flex justify-between py-2 border-b text-sm md:text-base">
            <span className="font-bold">ID:</span>
            <span>{rowData.id.substring(18)}</span>
          </div>
          <div className="flex justify-between py-2 border-b text-sm md:text-base">
            <span className="font-bold">Name</span>
            <span>{rowData.name}</span>
          </div>
          <div className="flex justify-between py-2 border-b selection:text-sm md:text-base">
            <span className="font-bold">Delivery Charges:</span>
            <span>{rowData.delivery_charges}</span>
          </div>
          <div className="flex justify-between py-2 text-sm md:text-base border-b">
            <span className="font-bold">Delivery offer:</span>
            <span>{rowData.delivery_offer}</span>
          </div>
          <div className="flex justify-between py-2 text-sm md:text-base border-b">
            <span className="font-bold">status:</span>
            <span>{rowData.status}</span>
          </div>{" "}
          <div className="flex justify-between py-2 text-sm md:text-base border-b">
            <span className="font-bold">Date:</span>
            <span>{dateFormatter(rowData.createdAt)}</span>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default BrandModal;
