import React from "react";
import ModjenLoader from "../../assets/images/ModjenLoader.gif";
import "./index.scss";

const Loader = () => {
  return (
    <div className="modjenLoader">
      <img src={ModjenLoader} alt="loader" />
    </div>
  );
};

export default Loader;
