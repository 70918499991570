import React, { useEffect } from "react";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { URL } from "../../../constants";
import { useUserContext } from "context";

const Topbar = ({ route, showTopBar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { onReset, userData, onGetAllUser, setSideBar } = useUserContext();

  const pageTitle = window.location.href.split("/");

  useEffect(() => {
    onGetAllUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuItemClick = () => {
    onReset();
  };





  return (
    <div
      className={`topBar transition-all transition-duration: 150ms flex flex-col md:flex-row  item-center   backdrop-blur-xl filter-none rounded-lg p-3 md:items-center z-[100] w-full sticky top-0 justify-between
     ${route !== URL.SETTING.BASE ||
          route !== URL.WRONG.BASE ||
          route !== URL.FINAL.BASE
          ? " fixed justify-end right-5"
          : ""
        }
   `}
    >
      {route !== URL.SETTING.BASE &&
        route !== URL.WRONG.BASE &&
        route !== URL.FINAL.BASE ? (
        <h1 className="text-[34px] text-black font-bold capitalize">
          {pageTitle.length > 4
            ? pageTitle[4].includes("-")
              ? pageTitle[4].replace("-", " ")
              : pageTitle[4]
            : pageTitle[3]}
        </h1>
      ) : (
        ""
      )}

      <div className="bg-white rounded-full py-[9px] px-[11px] shadow flex items-center gap-3 justify-start">
        <div className="transition-all cursor-pointer ">
          <MenuIcon onClick={() => setSideBar(prev => !prev)} />
        </div>

        <button
          className="bg-[#FF2F60] cursor-pointer rounded-full py-[10px] px-[11px] font-medium text-white"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        >
          {userData?.data[0]?.username.split(' ').map(word => word[0]).join('').toUpperCase()}
        </button>
        <div
          className={` absolute top-16  right-0  transition-all duration-500 `}
        >
          {
            <div
              className={`transition-all transition-duration:500ms bg-white rounded-[20px] shadow-lg mt-3  w-[200px] z-10 overflow-hidden  `}
              style={{ maxHeight: isDropdownOpen ? "180px" : "0px" }}
            >
              <div
                className="text-black p-2 py-4 text-[14px] pl-5 border-b font-bold"
              // onClick={onMenuItemClick}
              >
                👋 Hi, {userData?.data[0]?.username}
              </div>
              <div className="p-[10px]">
                <div
                  className="text-black p-2 text-[14px] font-normal cursor-pointer"
                // onClick={onMenuItemClick}
                >
                  Change Password
                </div>
                <div
                  className=" p-2 text-[#FF2F60] text-[14px] cursor-pointer"
                  onClick={onMenuItemClick}
                >
                  Log out
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>


  );
};

export default Topbar;
