 import s3 from "utils/Aws/S3"; 

function ImageUploader({ setImageURL }) {
  // const [loader, setLoader] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setLoader(true);
    // const formData = new FormData();
    // formData.append("file", event.target.files[0]);
    // formData.append(
    //   "upload_preset",
    //   process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
    // );

    const file = event.target.files[0];

    s3.upload({
      Bucket: 'modjen-new-assets',
      Key: "images/" + file.name,
      Body: file,
    }, (err, data) => {
      if (err) {
        console.error('Error uploading file: ', err);
      } else {
        setImageURL(data.Location);
        console.log('File uploaded successfully. S3 URL: ', data.Location);
      }
    });

    // const response = await fetch(
    //   `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/upload`,
    //   {
    //     method: "POST",
    //     body: formData,
    //   }
    // );

    // const data = await response.json();
    // setImageURL(data.secure_url);
    // setLoader(false);
  };

  return (
    // <CloudinaryContext
    //   cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
    //   apiKey={process.env.REACT_APP_CLOUDINARY_PUBLIC_KEY}
    //   apiSecret={process.env.REACT_APP_CLOUDINARY_PRIVATE_KEY}
    // >
    //   <form>
    //     <input type="file" onChange={handleSubmit} hidden />
    //   </form>

    //   <div style={{ width: "80px", margin: "auto" }}>
    //     <img src={imageURL} />
    //   </div>
    // </CloudinaryContext>
    <form>
      <input type="file" onChange={handleSubmit} hidden />
    </form>

  );
}

export default ImageUploader;


  // <CloudinaryContext
  //     cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
  //     apiKey={process.env.REACT_APP_CLOUDINARY_PUBLIC_KEY}
  //     apiSecret={process.env.REACT_APP_CLOUDINARY_PRIVATE_KEY}
  //   > 

  //  {showImage && (
  //     <div >
  //       <img src={imageURL} />
  //     </div>
    
  //   </CloudinaryContext>