import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import CloseIcon from "@mui/icons-material/Close";
import { sidebarData } from "../Data/sidebarData";
import { useUserContext } from "context";




const Sidebar = ({ route }) => {
  const { toggleSideBar, setSideBar } = useUserContext()
  const navigate = useNavigate();
  const onStyle = () => { };

  return (
    <>
      <div className={`h-screen fixed lg:sticky top-0 bg-white shadow overflow-y-auto  z-50 overflow-x-hidden  ${toggleSideBar ? "w-0 " : "min-w-[300px] w-[300px] pt-3 px-5 "} transition-all duration-200 `}>
        <div className="flex flex-col relative ">
          <img src={logo} className="h-[72px] w-[180px]" alt="" />

          <div className="absolute top-0 right-0 py-4 cursor-pointer">
            <CloseIcon onClick={() => setSideBar(prev => !prev)} />
          </div>

        </div>
        <div className="mt-4">
          <ul className="">
            {sidebarData(route).map((items, i) => {
              return (
                <div
                  className="w-full flex justify-between relative pb-3"
                  key={i}
                  onClick={() => navigate(items.link)}
                >
                  <div
                    className={`flex items-center w-full gap-[1rem] h-[2.5rem] transition-all ease-in-out duration-300 hover:cursor-pointer`}
                  >
                    {items.icon}
                    <li
                      onClick={() => onStyle()}
                      className="text-[#2D3748] cursor-pointer leading-normal tracking-tighter font-sans pl-4 relative"
                    >
                      {items.page}
                      <div className="flex gap-5">{items.name}</div>
                    </li>
                  </div>
                  {items.active && (
                    <div className="absolute right-0 top-5 rounded transform -translate-y-1/2 w-1 h-10 bg-[#ff1d52]"></div>
                  )}
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
