import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import BarChartIcon from "@mui/icons-material/BarChart";
import StoreIcon from "@mui/icons-material/Store";
import { SIDE_NAVIGATION, URL } from "constants/index";
import SettingsIcon from "@mui/icons-material/Settings";
import CategoryIcon from "@mui/icons-material/Category";

export const sidebarData = (route) => [
  {
    icon: (
      <HomeIcon
        sx={{
          color: "#FF2F60",
          cursor: "pointer",
        }}
      />
    ),
    name: (
      <span
        className={
          route === URL.DASHBOARD.BASE
            ? "text-black font-bold"
            : "text-[#A4ADC7] font-normal"
        }
      >
        {SIDE_NAVIGATION.DASHBOARD}
      </span>
    ),
    link: URL.DASHBOARD.BASE,
    active: route === URL.DASHBOARD.BASE ? true : false,
  },
  {
    icon: (
      <PersonIcon
        sx={{
          color: "#FF2F60",
          cursor: "pointer",
        }}
      />
    ),
    link: URL.USERS.BASE,
    name: (
      <span
        className={
          route === URL.USERS.BASE
            ? "text-black font-bold"
            : "text-[#A4ADC7] font-normal"
        }
      >
        {SIDE_NAVIGATION.USERS}
      </span>
    ),
    active: route === URL.USERS.BASE ? true : false,
  },
  {
    icon: (
      <BarChartIcon
        sx={{
          color: "#FF2F60",
          cursor: "pointer",
        }}
      />
    ),
    link: URL.ORDERS.BASE,
    name: (
      <span
        className={
          route === URL.ORDERS.BASE
            ? "text-black font-bold"
            : "text-[#A4ADC7] font-normal"
        }
      >
        {SIDE_NAVIGATION.ORDERS}
      </span>
    ),
    active: route === URL.ORDERS.BASE ? true : false,
  },
  {
    icon: (
      <StoreIcon
        sx={{
          color: "#FF2F60",
          cursor: "pointer",
        }}
      />
    ),
    link: URL.BRANDS.BASE,
    name: (
      <span
        className={
          route === URL.BRANDS.BASE
            ? "text-black font-bold"
            : "text-[#A4ADC7] font-normal"
        }
      >
        {SIDE_NAVIGATION.BRANDS}
      </span>
    ),
    active: route === URL.BRANDS.BASE ? true : false,
  },
  {
    icon: (
      <StoreIcon
        sx={{
          color: "#FF2F60",
          cursor: "pointer",
        }}
      />
    ),
    link: URL.PRODUCTS.BASE,
    name: (
      <span
        className={
          route === URL.PRODUCTS.BASE
            ? "text-black font-bold"
            : "text-[#A4ADC7] font-normal"
        }
      >
        {SIDE_NAVIGATION.PRODUCTS}
      </span>
    ),
    active: route === URL.PRODUCTS.BASE ? true : false,
  },
  // {
  //   icon: (
  //     <WebIcon
  //       sx={{
  //         color: "#FF2F60",
  //         cursor: "pointer",
  //       }}
  //     />
  //   ),
  //   link: URL.BANNERS.BASE,
  //   name: (
  //     <span
  //       className={
  //         route === URL.BANNERS.BASE
  //           ? "text-black font-bold"
  //           : "text-[#A4ADC7] font-normal"
  //       }
  //     >
  //       Banners
  //     </span>
  //   ),
  //   active: route === URL.BANNERS.BASE ? true : false,
  // },
  {
    icon: (
      <CategoryIcon
        sx={{
          color: "#FF2F60",
          cursor: "pointer",
        }}
      />
    ),
    link: URL.SUBCATEGORY.BASE,
    name: (
      <span
        className={
          route === URL.SUBCATEGORY.BASE
            ? "text-black font-bold"
            : "text-[#A4ADC7] font-normal"
        }
      >
        {SIDE_NAVIGATION.SUB_CATEGORY}
      </span>
    ),
    active: route === URL.SUBCATEGORY.BASE ? true : false,
  },
  {
    icon: (
      <SettingsIcon
        sx={{
          color: "#FF2F60",
          cursor: "pointer",
        }}
      />
    ),
    link: URL.SETTING.BASE,
    name: (
      <span
        className={
          route === URL.SETTING.BASE
            ? "text-black font-bold"
            : "text-[#A4ADC7] font-normal"
        }
      >
        {SIDE_NAVIGATION.PRODUCTS_SETTING}
      </span>
    ),
    active: route === URL.SETTING.BASE ? true : false,
  },
  {
    icon: (
      <SettingsIcon
        sx={{
          color: "#FF2F60",
          cursor: "pointer",
        }}
      />
    ),
    link: URL.WRONG.BASE,
    name: (
      <span
        className={
          route === URL.WRONG.BASE
            ? "text-black font-bold"
            : "text-[#A4ADC7] font-normal"
        }
      >
        {SIDE_NAVIGATION.WRONG_PRODUCTS}
      </span>
    ),
    active: route === URL.WRONG.BASE ? true : false,
  },
  {
    icon: (
      <SettingsIcon
        sx={{
          color: "#FF2F60",
          cursor: "pointer",
        }}
      />
    ),
    link: URL.FINAL.BASE,
    name: (
      <span
        className={
          route === URL.FINAL.BASE
            ? "text-black font-bold"
            : "text-[#A4ADC7] font-normal"
        }
      >
        {SIDE_NAVIGATION.FINAL_PRODUCTS}
      </span>
    ),
    active: route === URL.FINAL.BASE ? true : false,
  },
  {
    icon: (
      <PersonIcon
        sx={{
          color: "#FF2F60",
          cursor: "pointer",
        }}
      />
    ),
    link: URL.CONTACT_US.BASE,
    name: (
      <span
        className={
          route === URL.CONTACT_US.BASE
            ? "text-black font-bold"
            : "text-[#A4ADC7] font-normal"
        }
      >
        {SIDE_NAVIGATION.CONTACT_US}
      </span>
    ),
    active: route === URL.CONTACT_US.BASE ? true : false,
  },
];
