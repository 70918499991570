import { TableCell, TableRow } from "@mui/material";
import { deleteBrandEndPoint } from "api/endpoints/brand";
import { capitalizeString } from "api/integration/functions";
import { toastError, toastSuccess } from "api/integration/toast";
import { HTTP_STATUS_CODES } from "constants";
import { useUserContext } from "context";
import React, { useState } from "react";
import { dateFormatter } from "utils/numberFormatter/date";
import ActionDropdown from "views/Brands/components/ActionDropdown";

const BrandTableRow = ({ row, handleOpenModal }) => {
  const { token, onGetAllBrand } = useUserContext();
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const onDeleteBrand = async () => {
    try {
      const response = await deleteBrandEndPoint(token, row.id);
      if (response && response.statusCode === HTTP_STATUS_CODES.OK) {
        onGetAllBrand();
        toastSuccess("Action Performed Successfully");
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.code}
      onClick={() => handleOpenModal(row)}
      sx={{ cursor: "pointer" }}
    >
      <TableCell sx={{ fontWeight: "700" }}>{row.id.substring(18)}</TableCell>

      <TableCell>{capitalizeString(row.name)}</TableCell>
      <TableCell>
        <img
          src={row.logo}
          alt={`${row.name} Logo`}
          style={{
            maxWidth: "100%",
            height: "auto",
            width: "50%",
          }}
        />
      </TableCell>
      <TableCell>
        <img
          src={row.thumbnail}
          alt={`${row.name} Thumnail`}
          style={{
            maxWidth: "100%",
            height: "auto",
            width: "50%",
          }}
        />
      </TableCell>
      <TableCell>{row.delivery_charges}</TableCell>
      <TableCell>{row.delivery_offer}</TableCell>
      <TableCell>{capitalizeString(row.status)}</TableCell>
      <TableCell>{dateFormatter(row.createdAt)}</TableCell>
      <TableCell>
        <img
          src={row.banner_desktop}
          alt={`${row.name} `}
          style={{
            height: "auto",
            width: "100%",
          }}
        />
      </TableCell>
      <TableCell>
        <img
          src={row.banner_mobile}
          alt={`${row.name} `}
          style={{
            height: "auto",
            width: "100%",
          }}
        />
      </TableCell>

      <TableCell onClick={(event) => event.stopPropagation()}>
        <ActionDropdown
          openModal={openModal}
          setOpenModal={setOpenModal}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          onDeleteBrand={onDeleteBrand}
          row={row}
        />
      </TableCell>
    </TableRow>
  );
};

export default BrandTableRow;
